<template>
  <div>
    <!-- begin card -->
    <div class="card bg-grey-lighter m-b-5 col-md-12">
      <div class="card-body  ">
        <blockquote class="blockquote ">
          <h2 class="page-header text-left text-black-lighter">网格长列表</h2>
        </blockquote>
      </div>
    </div>
    <!-- end card -->
    <div class="register-content col-md-12">
      <form>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <vxe-toolbar ref="xToolbar" :loading="loading">
              <template #buttons>
                <vxe-button
                  status="primary"
                  content="新增"
                  @click="insertEvent"
                ></vxe-button>
              </template>
            </vxe-toolbar>

            <vxe-table
              align="center"
              :loading="loading"
              round
              border
              height="650"
              :data="tableData"
              ref="xTable"
            >
              <!-- <vxe-column field="" title="头像"> </vxe-column> -->
              <vxe-column field="userName" title="账号"> </vxe-column>
              <vxe-column field="name" title="姓名"> </vxe-column>
              <vxe-column field="phoneNumber" title="电话号码"> </vxe-column>
              <vxe-column field="lastModificationTime" title="最后登录">
              </vxe-column>
              <vxe-column title="操作" fixed="right" width="240">
                <template #default="{ row }">
                  <vxe-button
                    status="warning"
                    content="编辑"
                    @click="editEvent(row)"
                  ></vxe-button>
                  <vxe-button
                    status="danger"
                    content="删除"
                    @click="removeRowEvent(row)"
                  ></vxe-button>
                </template>
              </vxe-column>
            </vxe-table>
            <vxe-pager
              border
              icon-prev-page="fa fa-angle-left"
              icon-jump-prev="fa fa-angle-double-left"
              icon-jump-next="fa fa-angle-double-right"
              icon-next-page="fa fa-angle-right"
              icon-jump-more="fa fa-ellipsis-h"
              :loading="loading"
              :current-page="tablePage.currentPage"
              :page-size="tablePage.pageSize"
              :total="tablePage.totalResult"
              :layouts="[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'FullJump',
                'Sizes',
                'Total',
              ]"
              @page-change="handlePageChange"
            >
            </vxe-pager>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { gridheadApi } from "@/api/systembasic/gridheadApi";
import Gridhead from "./Gridhead.vue";
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  created() {},
  mounted() {
    // 页面初始化时运行
    this.GetGridheadList();
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    //获取企业列表数据
    GetGridheadList() {
      let that = this;
      (that.loading = true),
        gridheadApi
          .GetGridheadList({
            SkipCount:
              (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
            MaxResultCount: that.tablePage.pageSize,
            Sorting: "",
            Filter: "",
          })
          .then((res) => that.GetSuccess(that, res))
          .finally(() => {
            that.loading = false;
          });
    },
    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        //获取数据成功后数据传到Data里给页面赋值
        that.tableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    // 删除网格长信息
    removeRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              gridheadApi
                .DeleteGridhead({ id: row.id })
                .then((res) => that.DeleteGridheadSuccess(that, res, row))
                .finally(() => {});
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeleteCompanySuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.xTable;
        $table.remove(row);
        this.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetCompanyData();
      // this.findList();
    },
    // 进入网格长信息页面
    async editEvent(row) {
      this.$modal.show(
        Gridhead,
        { Gridheadid: row.id },
        { height: "320", width: "50%", draggable: true }
      );
      // this.$router.push({ path: "/Gridhead?id=" + row.id});
    },
    insertEvent() {
      this.$router.push({ path: "/Gridhead?id=" });
    },
    // 表
  },
};
</script>
