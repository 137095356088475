var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"register-content col-md-12"},[_c('form',[_c('div',{staticClass:"panel panel-inverse",attrs:{"data-sortable-id":"form-stuff-1"}},[_c('div',{staticClass:"panel-body"},[_c('vxe-toolbar',{ref:"xToolbar",attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-button',{attrs:{"status":"primary","content":"新增"},on:{"click":_vm.insertEvent}})]},proxy:true}])}),_c('vxe-table',{ref:"xTable",attrs:{"align":"center","loading":_vm.loading,"round":"","border":"","height":"650","data":_vm.tableData}},[_c('vxe-column',{attrs:{"field":"userName","title":"账号"}}),_c('vxe-column',{attrs:{"field":"name","title":"姓名"}}),_c('vxe-column',{attrs:{"field":"phoneNumber","title":"电话号码"}}),_c('vxe-column',{attrs:{"field":"lastModificationTime","title":"最后登录"}}),_c('vxe-column',{attrs:{"title":"操作","fixed":"right","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-button',{attrs:{"status":"warning","content":"编辑"},on:{"click":function($event){return _vm.editEvent(row)}}}),_c('vxe-button',{attrs:{"status":"danger","content":"删除"},on:{"click":function($event){return _vm.removeRowEvent(row)}}})]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.tablePage.currentPage,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalResult,"layouts":[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bg-grey-lighter m-b-5 col-md-12"},[_c('div',{staticClass:"card-body  "},[_c('blockquote',{staticClass:"blockquote "},[_c('h2',{staticClass:"page-header text-left text-black-lighter"},[_vm._v("网格长列表")])])])])}]

export { render, staticRenderFns }